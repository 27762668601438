<template>
  <el-dialog
    :visible.sync="showDateTimeModal"
    :show-close="false"
    :close-on-click-modal="false"
    style="padding: 0; text-align:left"
    class="dialogue"
  >
    <div class="dialogue__heading">
      <h2>Scheduler</h2>
      <div class="dialogue__close" @click="$emit('close')">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3 10.0006L19.4873 3.81337C20.1709 3.12974 20.1709 2.02141 19.4873 1.33885L18.6624 0.514004C17.9786 -0.169839 16.8703 -0.169839 16.1877 0.514004L10.0006 6.70106L3.81337 0.512722C3.12974 -0.170907 2.02141 -0.170907 1.33885 0.512722L0.512722 1.33756C-0.170907 2.02141 -0.170907 3.12974 0.512722 3.8123L6.70106 10.0006L0.514004 16.1877C-0.169839 16.8715 -0.169839 17.9799 0.514004 18.6624L1.33885 19.4873C2.02247 20.1709 3.13081 20.1709 3.81337 19.4873L10.0006 13.3L16.1877 19.4873C16.8715 20.1709 17.9799 20.1709 18.6624 19.4873L19.4873 18.6624C20.1709 17.9786 20.1709 16.8703 19.4873 16.1877L13.3 10.0006Z"
            fill="#DB353A"
          />
        </svg>
      </div>
    </div>
    <form
      class="form"
      @submit.prevent="submitForm"
      novalidate="true"
      autocomplete="off"
    >
      <h3>Select Date and Time</h3>

      <div class="form__group--six">
        <div class="form__group--three">
          <label for="date" class="form__label">
            <input
              type="date"
              id="date"
              name="date"
              placeholder="date"
              class="form__input"
              v-model.lazy="$v.form.startDate.$model"
              :class="{
                'is-invalid': submitted && $v.form.startDate.$error
              }"
          /></label>
          <small class="error" v-if="submitted && !$v.form.startDate.required"
            >Date is required</small
          >
        </div>

        <div class="form__group--three">
          <label for="time" class="form__label">
            <input
              type="time"
              id="time"
              name="time"
              placeholder="time"
              class="form__input"
              v-model.lazy="$v.form.startTime.$model"
              :class="{
                'is-invalid': submitted && $v.form.startTime.$error
              }"
          /></label>
          <small class="error" v-if="submitted && !$v.form.startTime.required"
            >Time is required</small
          >
        </div>
      </div>

      <button type="submit" class="btn btn__red btn__group">
        <span>Continue</span>
      </button>
    </form>
  </el-dialog>
</template>

<script>
import dayjs from "dayjs";
import { required } from "vuelidate/lib/validators";
export default {
  props: ["showDateTimeModal", "laterDate"],
  data() {
    return {
      submitted: false,
      form: {
        startDate: "",
        startTime: ""
      }
    };
  },
  validations: {
    form: {
      startDate: { required },
      startTime: { required }
    }
  },
  methods: {
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      const combinedDateString =
        this.form.startDate + " " + this.form.startTime;

      let combinedDate = new Date(combinedDateString);
      combinedDate = combinedDate.toISOString();

      this.$emit("selectedDate", combinedDate);
    }
  },
  mounted() {
    if (this.laterDate !== "") {
      this.form.startDate = dayjs(this.laterDate).format("YYYY-MM-DD");
      this.form.startTime = dayjs(this.laterDate).format("HH:MM");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
